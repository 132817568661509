require('./bootstrap');

function onScroll() {
    var scrollPos = $(document).scrollTop();
    $("body").find(".animate").each(function () {
        var animateClass = $(this).attr('animateClass');
        if (animateClass && !$(this).hasClass(animateClass)) {
            if ($(this).offset().top - scrollPos < $(window).height()-200) {
                $(this).on('animationend webkitAnimationEnd oAnimationEnd', function () {
                    $(this).addClass('animated');
                });
                $(this).addClass(animateClass);
            }
        }
    });

    parallaxHomeslider();
}
$(document).scroll(onScroll);
onScroll();
setTimeout(onScroll,100);

function parallaxHomeslider () {
    var $homeSlider = $(".headerVideoBox:visible");

    if ($homeSlider.length > 0) {
        var windowTop = ((window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0));
        $homeSlider.css({'top': (windowTop * 0.25 * -1)});
        $("main").css({'margin-top':$homeSlider.height()});
    }
}



$(document).ready(function(){

    $(".scrollTop").click(function () {
        $("html, body").animate({scrollTop: 0}, "slow");
        return false;
    });

    var delay = 0;
    $(".category").each(function(){
        $(this).css({'animation-delay':delay+'s'});
        delay+= 0.05;
    });

    $("#profileBtn").click(function(event){

        $("#logoutModal").modal();
    });

    var vidTrack = false;
    $(".vidImg").click(function(){
       var src = $(this).attr('vidSrc');
       $("#videoPlayer").attr('src',src);
       $("#videoModal").modal();
        $("#videoPlayer").get(0).play();

        vidTrack = setInterval(trackVid,10000)
        trackVid();
    });

    function trackVid()
    {
        var currentTime = $("#videoPlayer").get(0).currentTime;
        var data = {
            '_token': $("meta[name=csrf-token]").attr('content'),
            'key': 'video',
            'value': currentTime,
            'reference': $("#videoPlayer").attr('src')
        }
        $.ajax({
            type: "POST",
            url: '/events',
            data: data,
            success: function(){

            },
            dataType: 'json'
        });
    }
    $('#videoModal').on('hidden.bs.modal', function () {
        $('#videoPlayer').get(0).pause();
        $('#videoPlayer').removeAttr('src');
        if(vidTrack)
        {
            clearInterval(vidTrack);
            vidTrack = false;
        }
    });


    $(".appImg").click(function(){
        var src = $(this).attr('appSrc');
        $("#appFrame").attr('src',src);
        $("#appModal").modal();
    });

    $('#appModal').on('hidden.bs.modal', function () {
        $('#appFrame').removeAttr('src');
    });


    $(".disclaimerBtn").click(function(){
        $("#disclaimer").modal();
    });

    if($("meta[name=show_disclaimer]").attr('content') == 1)
    {
        $("#disclaimer").modal();
    }

    $('#disclaimer').on('hidden.bs.modal', function () {
        var data = {
            '_token': $("meta[name=csrf-token]").attr('content'),
            'disclaimer': 1
        }
        $.ajax({
            type: "POST",
            url: '/disclaimer',
            data: data,
            success: function(){

            },
            dataType: 'json'
        });
    });

});
